<template>
    <div>
        <HeaderNavigation :info="$store.state.infos" :checkValue="12" />
        <div class="top_pic"><img :src="banner" alt=""></div>

        <div style="text-align:center;margin-top:30px;" class="title1">产品介绍</div>
        <div class="title-box" style="margin-bottom:30px">
            <div v-for="(t, idx) in titleList" :key="idx" :class="active_type_id == t.id  ? 'type-box type-box-hover' : 'type-box' "  @click="changeType(t)">
              <img :src="t.image"  style="width:56px;height:56px;"/>
              <div>{{ t.type_name }}</div>
            </div>
        </div>
        
      <div class="one_box">
        <div class="prdocut_box" v-for="(item, index) of filterProduct" :key="index">
          <div   v-if="item.product_name" class="item_one_kuai"  @click="JumpDetail(item)" >
            <div class="item_one_kuai_shop_pic"><img :src="item.product_image" alt=""></div>
            <div class="bottom_txt">
              {{ item.product_name }}
            </div>
          </div>
        </div>
      </div>
        <!-- <div class="title-box">
            <div class="shop_box">
                <div class="nav_title_box">
                    <div @click="classClick(item)" :class="item.id==classValue?'yes_item_class_box':'item_class_box'" v-for="(item,index) of titleList" :key="index">{{item.type_name}}</div>
                </div>
                <div class="nav_content_box">
                    <div class="item_content_box" v-for="(item,index) of shopList" :key="index">
                        <div  v-if="item.product_name" class="nei_item_content_box" @click="JumpDetail(item)" >
                            <div class="item_content_box_pic"><img :src="item.product_image" alt=""></div>
                            <div class="item_content_box_title">{{item.product_name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

      <BottomNavigation :info="$store.state.infos" />
      <RightMenu :info="$store.state.infos" @openpop="openpop" />
      <JoinBulletFrame v-if="JoinModal" @closepop="closepop" />
    </div>
</template>

<script>
    import {configshow, type_list,product_list,cateBanner} from '@/api/index.js'
    import HeaderNavigation from "@/components/header_navigation.vue";
    import BottomNavigation from "@/components/bottom_navigation.vue";
    import RightMenu from "@/components/right-menu.vue";
    import JoinBulletFrame from "@/components/joinBulletFrame.vue";
    export default {
        components: {
            HeaderNavigation,
            BottomNavigation,
            RightMenu,
            JoinBulletFrame
        },
        data(){
            return{
                classValue:1,
                titleList:[],
                banner:'',
                shopList:[],
                JoinModal:false,
                active_type_id:0,
            }
        },
        computed: {
          filterProduct() {
             return this.shopList.filter(p =>
              p.type_id == this.active_type_id
            );
          }
        },
        created() {
            this.configshow();
            window.scroll(0, 0)
            this.type_list()
            this.cateBanner()
            this.product_list()
        },
        methods:{
          changeType(t){
              this.active_type_id = t.id
          },
          configshow(){
            configshow().then(res=>{
              this.info = res.data
              this.$store.commit('setInfos',  this.info);
            })
          },
          cateBanner(){
                cateBanner({id:12}).then(res=>{
                    this.banner = res.data
                })
            },
            type_list(){
                type_list().then(res=>{
                    this.classValue = res.data[0].id
                    this.titleList = res.data
                    this.active_type_id = this.$route.query.id ? this.$route.query.id : this.titleList[0].id
                })
            },
            product_list(){
                product_list().then(res=>{
                    this.shopList = res.data
                })
            },
            classClick(e){
                this.classValue = e.id
                this.product_list()
            },
            openpop(e){
                this.JoinModal = e
            },
            closepop(e){
                this.JoinModal = e
            },
            JumpDetail(e){
                // localStorage.setItem('Journalism',JSON.stringify(e))
                this.$router.push({path:"/productinfo",query:{id:e.id}})
            },
        }
    }
</script>

<style scoped>
.top_pic{
    width: 100%;
    height: 600px;
}
.top_pic img{
    width: 100%;
    height: 100%;
  object-fit: fill;
}
.shop_da_box{
  width:1100px;
  margin: 0 auto;
  padding: 50px 0;
}
.shop_box{
  width: 100%;
  margin-top: 30px;
  display: flex;
}
.nav_title_box{
  width: 180px;
}
.item_class_box{
  width: 140px;
  border-radius: 12px;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 隐藏溢出的内容 */
  text-overflow: ellipsis;
}
.yes_item_class_box{
  width: 140px;
  border-radius: 12px;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  background: #A91800;
  color: #FFF;
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 隐藏溢出的内容 */
  text-overflow: ellipsis;
}
.nav_content_box{
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.nav_content_box::after{
  width: 100%;
  height: 190px;
  display: block;
  content: ' ';
}
.item_content_box{
  width: 23%;
}
.nei_item_content_box{
  width: 100%;
  height:220px;
  margin-bottom: 20px;
  background: #F7F7F7;
  cursor: pointer;
}
.item_content_box_pic{
  width: 100%;
  height: 180px;
}
.item_content_box_pic img{
  width: 100%;
  height:100%;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.item_content_box_title{
  text-align: center;
  margin: 2px 0;
  height: 40px;
  background: #313131;
  align-items: center;
  justify-content: center;
  color: #fff;
  display: flex;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.title1 {
  font-size: 28px;
  font-weight: 600;
}
.title-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:80%;
  margin:20px auto 10px
}
.type-box{
  text-align: center;width:130px;padding:20px 0;cursor: pointer;
}
.type-box-hover{
  box-shadow: rgba(31, 149, 220, 0.2) 0px 2px 8px 0px;
}

.one_box {
  width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.one_box::after {
  display: block;
  width: 382px;
  content: ' ';
}

.item_one_kuai {
      position: relative;
    width: 382px;
    height: 260px;
    margin-bottom: 20px;
    border-radius: 12px;
    overflow: hidden; 
    cursor: pointer;
}
.item_one_kuai_shop_pic{
  width: 100%;
  height: 200px;
  transition: 0.3s all;
}
.item_one_kuai_shop_pic:hover{
  width: 120%;
  transition: 0.3s all;
}
.item_one_kuai_shop_pic img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bottom_txt {
  width: 100%;
  height: 60px;
  background: #9a9a9a;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
</style>